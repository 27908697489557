import React from "react";
import { Treatment, Container, Price, NewsBlog } from "../../components";
import Seo from "../../components/seo";

const TreatmentPage = () => {
  return (
    <Container>
      <Seo title="施術内容" />
      <div className="py-8 bg-white">
        <Treatment />
        <Price />
        <NewsBlog />
      </div>
    </Container>
  );
};

export default TreatmentPage;
